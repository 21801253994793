import WebServiceRequest from "../Api/WebServiceRequest";

class CreateABlog extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Blog/Create");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetAllBlogs extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Blog/GetAllV2");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class BlogDeleteRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
  }
  setId(id) {
    super.setTag("Blog/DeleteBy?BlogId=" + id);
  }
}

class GetASingleBlog extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Blog/GetBy");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class UpdateABlog extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Blog/Update");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class UpdateBlogSeo extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Blog/UpdateSEO");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

export {
  CreateABlog,
  GetAllBlogs,
  GetASingleBlog,
  UpdateABlog,
  UpdateBlogSeo,
  BlogDeleteRequest,
};
